import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { FormProvider, useForm } from 'react-hook-form'

import { toSnakeCase } from '@cutover/api'
import { Box, EditPanel, Message, useNotify } from '@cutover/react-ui'
import { IntegrationsList } from './integrations-list'
import {
  EditRunbookIntegrationPayload,
  useRunbookIntegrationsQuery,
  useUpdateRunbookFieldValues
} from '../../../../services/queries/use-runbook-integrations'
import { Runbook } from 'main/components/runbook/right-panels/people-panel/types'
import { useLanguage } from 'main/services/hooks/use-language'
import { FieldValuesAttributes, RunbookIntegration } from 'main/services/queries/types'
import { RunbookEditResponse } from 'main/services/api/data-providers/runbook-types'

type IntegrationsPanelProps = {
  runbook: Runbook
  runbookVersionId: number
}

export type EditIntegrationCustomFieldFormType = {
  runbook: {
    field_values: {
      [key: number | string]: FieldValuesAttributes
    }
  }
}

export const IntegrationsPanel = ({ runbook, runbookVersionId }: IntegrationsPanelProps) => {
  const { t } = useLanguage()
  const notify = useNotify()
  const runbookId = runbook.id

  const { data } = useRunbookIntegrationsQuery({
    runbookId,
    runbookVersionId
  })
  const canUpdateIntegrations = data && data?.meta.permissions.update.length > 0

  const disableIntegrationLinks =
    !canUpdateIntegrations &&
    (runbook.stage !== 'planning' ||
      (runbook.templateType === 'default' && runbook.templateStatus !== 'template_draft'))

  const updateRunbookFieldValuesMutation = useUpdateRunbookFieldValues({ runbookId })

  const [runbookIntegrations, setRunbookIntegrations] = useState<RunbookIntegration[] | undefined>(
    data?.integrationLinks
  )
  const [defaultValues, setDefaultValues] = useState({})

  const buildDefaultCustomFieldFormValues = (): EditIntegrationCustomFieldFormType => {
    const fieldValues = toSnakeCase(runbookIntegrations?.flatMap(ri => ri.fieldValues) || [])
    const customFields = toSnakeCase(runbookIntegrations?.flatMap(ri => ri.customFields) || [])
    const defaultValues: EditIntegrationCustomFieldFormType = { runbook: { field_values: {} } }
    customFields?.forEach(cf => {
      const fieldValue = fieldValues?.find(fv => fv.custom_field_id === cf.id)
      defaultValues.runbook.field_values[cf.id] = {
        id: fieldValue?.id,
        custom_field_id: cf.id,
        field_option_id: fieldValue?.field_option_id,
        remote_data_key_value: fieldValue?.remote_data_key_value,
        data_source_value_id: fieldValue?.data_source_value_id,
        value: fieldValue?.value
      }
    })
    return defaultValues
  }

  const methods = useForm<EditIntegrationCustomFieldFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleClose = () => {
    eventManager.emit('close-runbook-integrations-panel')
  }

  const resetForm = () => {
    methods.reset(defaultValues)
  }

  const buildRunbookUpdatePayload = (data: EditIntegrationCustomFieldFormType) => {
    return Object.entries(data.runbook.field_values).map((value: [string, FieldValuesAttributes]) => {
      const fieldAttribute = value[1]
      return Object.keys(fieldAttribute)
        .filter(k => fieldAttribute[k as keyof FieldValuesAttributes])
        .reduce((obj, k) => {
          const key = k as keyof FieldValuesAttributes
          obj[key] = fieldAttribute[key]
          return obj
        }, {} as FieldValuesAttributes)
    })
  }

  const onSubmitForm = async (data: any) => {
    const payload: EditRunbookIntegrationPayload = {
      runbook: { field_values_attributes: buildRunbookUpdatePayload(data) }
    }

    updateRunbookFieldValuesMutation.mutate(payload, {
      onSuccess: (response: RunbookEditResponse) => {
        if (response.runbook.field_values.length > 0) {
          resetForm()
          response.runbook.field_values.forEach(fv => {
            methods.setValue(`runbook.field_values.${fv.custom_field_id}.id`, fv.id)
            methods.setValue(`runbook.field_values.${fv.custom_field_id}.value`, fv.value)
          })
          notify.success(t('runbook:integrationsPanel:customFieldForm:success'))
        } else {
          notify.error(t('runbook:integrationsPanel:error'))
        }
      },
      onError: () => {
        notify.error(t('runbook:integrationsPanel:error'))
      }
    })
  }

  useEffect(() => {
    if (updateRunbookFieldValuesMutation.isError) {
      updateRunbookFieldValuesMutation.error?.errorDetails?.forEach(error => {
        methods.setError(`runbook.field_values.${error?.meta?.custom_field_id}.value`, { message: error.message })
      })
    }
  }, [])

  useEffect(() => {
    setRunbookIntegrations(data?.integrationLinks)
  }, [data])

  useEffect(() => {
    setDefaultValues(buildDefaultCustomFieldFormValues())
  }, [runbookIntegrations])

  useEffect(() => {
    resetForm()
  }, [defaultValues])

  return (
    <EditPanel
      title={t('runbook:integrationsPanel:title')}
      onClose={handleClose}
      onSubmit={() => methods.handleSubmit(onSubmitForm)()}
      onReset={resetForm}
      isDirty={methods.formState.isDirty}
      isSubmitting={methods.formState.isSubmitting}
    >
      <FormProvider {...methods}>
        <form>
          <Box direction="column" height="100%" css="flex-grow: 1;">
            {Object.keys(methods.formState?.errors?.runbook?.field_values || {})?.map(customFieldId => (
              <Box margin={{ bottom: '16px' }}>
                <Message
                  type="error"
                  key={customFieldId}
                  message={methods.formState?.errors?.runbook?.field_values?.[customFieldId]?.value?.message as string}
                />
              </Box>
            ))}
            <IntegrationsList
              disabled={disableIntegrationLinks}
              runbookIntegrations={runbookIntegrations || []}
              runbookId={runbookId}
              runbookVersionId={runbookVersionId}
            />
          </Box>
        </form>
      </FormProvider>
    </EditPanel>
  )
}
