import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import parse from 'html-react-parser'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, LoadingPanel, Message, RadioboxGroup, Text } from '@cutover/react-ui'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks/use-language'
import { useMergeRunbooks } from 'main/services/queries/merge-runbooks-query'
import { useValidateMerge } from 'main/services/queries/validate-merge-query'
import { MergeValidationSummary } from 'main/components/shared/merge-validation-summary'
import { useFeature } from 'main/services/hooks'
import { useProcessRunbookMergeResponse } from 'main/recoil/runbook/updaters/runbook-operations'

type ValidateSnippetProps = {
  snippetId: number
  runbookId: string
  taskId: number
  closeModal: () => void
}

export const ValidateSnippet = ({ snippetId, runbookId, taskId, closeModal }: ValidateSnippetProps) => {
  const [validationResponse, setValidationResponse] = useState<any>(null)
  const [errorList, setErrorList] = useState<string[]>([])
  const [validationIsLoading, setValidationIsLoading] = useState(true)
  const isReactRunbookEnabled = useFeature().isEnabled('react_runbook')
  const processRunbookMergeResponse = useProcessRunbookMergeResponse()

  const { t } = useLanguage()

  const validateMutation = useValidateMerge()
  const mergeMutation = useMergeRunbooks()

  const methods = useForm({
    defaultValues: { insertionType: 'sequence' }
  })

  useEffect(() => {
    const mutationPayload = {
      runbook_ids: [snippetId],
      target_runbook_id: runbookId,
      task_parent_id_to_link_to: taskId
    }
    validateMutation.mutate(mutationPayload, {
      onSuccess: data => {
        setValidationResponse(data)
        setValidationIsLoading(false)
      },
      onError: error => {
        const validationError = error as ApiError

        setErrorList(validationError?.errors)
        setValidationIsLoading(false)
      }
    })
  }, [])

  const onFormSubmit = ({ insertionType }: { insertionType: string }) => {
    const mutationPayload = {
      runbook_ids: [snippetId],
      target_runbook_id: runbookId,
      task_parent_id_to_link_to: taskId,
      shift_time: false,
      insertion_type: insertionType as 'parallel' | 'sequence'
    }
    mergeMutation.mutate(mutationPayload, {
      onSuccess: response => {
        if (isReactRunbookEnabled) {
          processRunbookMergeResponse(response)
        } else {
          eventManager.emit('update-runbook-page-on-snippet-added')
        }

        closeModal()
      }
    })
  }

  return (
    <Box>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        {errorList.length > 0 ? (
          <Box margin={{ bottom: '16px' }}>
            <Message type="error" message={errorList} />
          </Box>
        ) : validationIsLoading ? (
          <Box
            css={`
              height: 110px;
              position: relative;
            `}
          >
            <LoadingPanel />
          </Box>
        ) : (
          <>
            <Box margin={{ top: '16px' }}>
              <Text>{parse(t('runbook:validateSnippetModal.description'))}</Text>
            </Box>
            <MergeValidationSummary data={validationResponse} isSnippet />
          </>
        )}

        <Box
          margin={{ top: '16px' }}
          css={`
            position: relative;
          `}
        >
          <Controller
            name="insertionType"
            control={methods.control}
            defaultValue="sequence"
            render={({ field: { name, onChange, value, onBlur, ref } }) => (
              <RadioboxGroup
                name={name}
                label={t('runbook:validateSnippetModal:insertionType:label')}
                direction="row"
                required
                helpText={t('runbook:validateSnippetModal:insertionType:toolTip')}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                ref={ref}
                options={[
                  { value: 'sequence', label: t('runbook:validateSnippetModal:insertionType:inSequence') },
                  { value: 'parallel', label: t('runbook:validateSnippetModal:insertionType:inParallel') }
                ]}
              />
            )}
          />
        </Box>
        <Box direction="row" gap="xsmall" pad={{ bottom: '4px' }} justify="end" align="center">
          <Button
            tertiary
            label={t('common:cancelButton')}
            icon="dnd-forwardslash"
            onClick={closeModal}
            disabled={validateMutation.isLoading}
          />
          <Button
            primary
            type="submit"
            label={t('common:addButton')}
            loadingLabel={t('common:addingText')}
            icon="add"
            disabled={validateMutation.isLoading || mergeMutation.isLoading || validateMutation.isError}
            loading={mergeMutation.isLoading}
            data-cy="add-snippet"
          />
        </Box>
      </form>
    </Box>
  )
}
