import { useEffect, useMemo, useState } from 'react'
import { eventManager } from 'event-manager'
import { Heading } from 'grommet'
import queryString from 'query-string'
import { InfiniteQueryObserverResult } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import { useSetRecoilState } from 'recoil'
import { css } from 'styled-components'

import { Box, IconButton, LoadingPanel, media, Text } from '@cutover/react-ui'
import { ActivityFeedInput } from './feed/input/activity-feed-input'
import { incidentTimezone } from 'main/recoil/apps/apps-atoms'
import { ActivitiesResponse, ActivityConfig, ActivityVM, useActivities } from 'main/services/queries/use-activities'
import { useLanguage } from 'main/services/hooks/use-language'
import { ActivityFeed } from './feed/activity-feed'
import { ActivityFilterPanel } from './feed/filter/activity-filter-panel'

export type RunbookActivityPageProps = {
  incidentId: number
  runbookId: number
  currentUserId: number
  timezone?: string
}

export const RunbookActivityPage = ({ incidentId, runbookId, currentUserId, timezone }: RunbookActivityPageProps) => {
  const { t } = useLanguage('activities')
  const [searchParams] = useSearchParams()
  const setTimezone = useSetRecoilState<string>(incidentTimezone)
  const [filters, setFilters] = useState(getFilters(searchParams.toString()))
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [activityConfig, setActivityConfig] = useState<ActivityConfig[] | undefined>(undefined)
  const keys = (filters.key as string[]) || []

  const appliedFilterCount = keys.filter(key => key !== 'run.finished').length + Number(!!filters.featured)
  const { isLoading, data, isError, fetchNextPage } = useActivities(
    incidentId,
    { keys, featured: !!filters.featured },
    lastPage => lastPage?.activities[lastPage.activities.length - 1]?.id,
    runbookId
  )

  useEffect(() => {
    if (!activityConfig && data) {
      setActivityConfig(data?.pages[0]?.meta?.activity_config)
    }
  }, [activityConfig, data])

  useEffect(() => {
    timezone ? updateTimezone({ timezone }) : null
  }, [timezone])

  useMount(() => {
    eventManager.on('update-timezone', updateTimezone)
  })

  useUnmount(() => {
    eventManager.off('update-timezone', updateTimezone)
  })

  const updateTimezone = ({ timezone }: { timezone: string }) => {
    setTimezone(timezone)
  }

  const onFilterChange = (filters: string[]) => {
    const filterParamsString = getFilterParamsString(filters)
    setFilters(getFilters(encodeURI(filterParamsString)))
  }

  const getFilterParamsString = (filters: string[] | string[]) => {
    const filterParams = filters.map(filter => {
      if (filter === 'featured') return '&featured=true'
      return `&key[]=${filter}`
    })
    return filterParams.join('')
  }

  function getFilters(query: string) {
    return queryString.parse(query, { arrayFormat: 'bracket' })
  }
  const includeLiveActivityInFeed = (item: ActivityVM) => {
    const hasKey = keys.length && keys.includes(item.key)
    const isFeatured = !!filters.featured && item.featured
    const noKeysNoFeatured = !keys.length && !filters.featured

    return hasKey || isFeatured || noKeysNoFeatured
  }

  const items = data?.pages
    .flatMap(x => x?.activities)
    .filter(item => {
      return includeLiveActivityInFeed(item)
    })

  const badgeProp = useMemo(() => {
    if (appliedFilterCount === 0) {
      return undefined
    }
    return {
      type: 'dark' as const,
      label: appliedFilterCount,
      'data-testid': 'runbooks-filter-panel-toggle-badge'
    }
  }, [appliedFilterCount])

  return (
    <Box
      id="runbook-activity-page-container"
      height="100%"
      css={`
        flex-flow: column;
      `}
    >
      <Box
        id="header"
        direction="row"
        justify="between"
        align="center"
        gap="small"
        height="32px"
        margin={{ bottom: '12px' }}
        flex={false}
      >
        <Heading
          css={`
            ${media.sm(
              css`
                margin-left: 20px;
              `
            )}
          `}
          style={{ fontSize: '20px' }}
          level={3}
        >
          Activity Feed
        </Heading>
        <Box direction="row" gap="xsmall" pad={{ right: 'large' }}>
          <IconButton
            icon="filter"
            data-testid="activity-type-filter-button"
            label={`${t('activities:feed:typeFilter')}`}
            onClick={() => setShowFilterPanel(prev => !prev)}
            isActive={showFilterPanel}
            badge={badgeProp}
            size="medium"
          />
        </Box>
      </Box>
      <ActivityFilterPanel
        showFilterPanel={showFilterPanel}
        onFilterChange={onFilterChange}
        activityConfig={activityConfig}
        appliedFilterCount={appliedFilterCount}
      />
      <Box
        css={`
          position: relative;
          flex: 1;
        `}
      >
        {isLoading && <LoadingPanel />}
        {data && (
          <ActivityFeed<InfiniteQueryObserverResult<ActivitiesResponse, Error>>
            activities={items?.reverse()}
            activityConfig={activityConfig}
            getNextPage={fetchNextPage}
            canUpdate={data?.pages[0]?.meta?.permissions?.update.indexOf(currentUserId) !== -1}
            hasNextPage={data?.pages[data?.pages.length - 1]?.meta.pagination.has_more}
            totalSize={data?.pages[0]?.meta.pagination.total_size}
            emptyRender={
              <Box
                height="100%"
                width="100%"
                align="center"
                alignContent="center"
                css={`
                  font-weight: 500;
                  margin-top: auto;
                  padding: 1em;
                `}
                data-testid="activities-no-results"
              >
                <Box css="padding-bottom: 10vh">
                  <Text color="text-disabled">
                    {t('feed.noResults')}{' '}
                    <Box
                      css={`
                        text-decoration: underline;
                        padding: 0;
                      `}
                      as="button"
                      onClick={() => onFilterChange([])}
                      onKeyPress={() => onFilterChange([])}
                    >
                      {t('feed.clearFiltersButton')}
                    </Box>
                  </Text>
                </Box>
              </Box>
            }
          />
        )}
      </Box>
      {isError && <Text>{t('feed.error')}</Text>}
      <ActivityFeedInput runbookId={runbookId} />
    </Box>
  )
}
